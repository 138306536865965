import CoreApi from '../core-api'
import {
  ComponentRef,
  ControllerRef,
  StepData,
  ComponentStructre,
  ComponentConnectionItem,
} from '../api-types'
import * as _ from 'lodash'
import {
  LAST_STEP_ROLE,
  ROLE_FORM,
  STEP_ROLE,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
  ROLE_SUBMIT_BUTTON,
} from '../../../constants/roles'
import { STEP_POSITION } from './constants'
import {
  getStepPosition,
  hasValidConnectedStepsContainer,
  isNavigationButton,
  isSortableStep,
  findPrimmaryConnection,
  getReorderedSteps,
} from './utils'
import { COMPONENT_TYPES } from '../preset/fields/component-types'
import translations from '../../../utils/translations'
import {
  getComponentByRole,
  fetchPreset,
  convertPreset,
  connectComponentToConnection,
  limitComponentInContainer,
} from '../services/form-service'
import { undoable } from '../utils'
import { SPACE_BETWEEN_FIELDS } from '../fields/api'
import { MULTI_STEP_BUTTON_SIDE_MARGIN } from '../layout-panel/constants/layout-settings'

export default class StepsApi {
  private boundEditorSDK: any
  private coreApi: CoreApi

  constructor(boundEditorSDK, coreApi: CoreApi) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
  }

  public selectStep(stepsContainer: ComponentRef, index: number) {
    return this.boundEditorSDK.components.behaviors.execute({
      componentRef: stepsContainer,
      behaviorName: 'changeState',
      behaviorParams: { stateIndex: index },
    })
  }

  @undoable()
  public async reorderSteps(
    stepsContainerRef: ComponentRef,
    stepsData: StepData[],
    srcIndex: number,
    destIndex: number
  ): Promise<StepData[]> {
    if (srcIndex === destIndex) {
      return stepsData
    }

    const [{ currentIndex }, multiStepFormData] = await Promise.all([
      this.boundEditorSDK.components.behaviors.getRuntimeState({
        componentRef: stepsContainerRef,
      }),
      this.boundEditorSDK.components.serialize({ componentRef: stepsContainerRef }),
    ])
    const { currentStep: currentStepNewIndex, stepsData: reorderedSteps } = getReorderedSteps(
      stepsData,
      srcIndex,
      destIndex,
      stepsData[currentIndex].componentRef
    )

    await Promise.all([
      this.boundEditorSDK.components.arrangement.moveToIndex({
        componentRef: stepsData[srcIndex].componentRef,
        index: destIndex,
      }),
      this.selectStep(stepsContainerRef, currentStepNewIndex),
      this._fixStepsNavigationButtons(reorderedSteps, multiStepFormData),
      this._updateStepsTitles(reorderedSteps),
    ])

    this.selectStep(stepsContainerRef, currentStepNewIndex)

    return this.getSteps(stepsContainerRef)
  }

  private async _fixStepsNavigationButtons(
    stepsData: StepData[],
    multiStepFormData: ComponentStructre
  ) {
    const lastStepRole = stepsData[stepsData.length - 1].role
    const [nextButton, submitButton] = await this._getNavigationButtons(multiStepFormData, [
      ROLE_NEXT_BUTTON,
      ROLE_SUBMIT_BUTTON,
    ])
    return stepsData
      .map((step: StepData, index) => ({
        componentRef: step.componentRef,
        position: getStepPosition(index, stepsData.length, lastStepRole),
      }))
      .map(({ componentRef, position }) =>
        this._fixStepNavigation(componentRef, position, {
          nextButton,
          submitButton,
        })
      )
  }

  private async _fixStepNavigation(
    componentRef: ComponentRef,
    stepPos: STEP_POSITION,
    { nextButton, submitButton }: { nextButton: ComponentStructre; submitButton: ComponentStructre }
  ) {
    const [[submitButtonRef], [previousButtonRef], [nextButtonRef]] = await Promise.all([
      this.coreApi.findChildComponentsByRole(componentRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.findChildComponentsByRole(componentRef, ROLE_PREVIOUS_BUTTON),
      this.coreApi.findChildComponentsByRole(componentRef, ROLE_NEXT_BUTTON),
    ])
    if (stepPos === STEP_POSITION.OTHER) {
      return this._fixRegularStep(componentRef, previousButtonRef, submitButtonRef, nextButton)
    }
    if (stepPos === STEP_POSITION.FIRST_STEP) {
      return this._fixFirstStep(componentRef, previousButtonRef, submitButtonRef, nextButton)
    }
    if (stepPos === STEP_POSITION.FIRST_AND_LAST_STEP) {
      return this._fixFirstLastStep(componentRef, previousButtonRef, nextButtonRef, submitButton)
    }
    if (stepPos === STEP_POSITION.LAST_STEP) {
      return this._fixLastStep(componentRef, previousButtonRef, nextButtonRef, submitButton)
    }
  }

  private _fixRegularStep(
    stepComponentRef: ComponentRef,
    previousButtonRef: ComponentRef,
    submitButtonRef: ComponentRef,
    nextButton: ComponentStructre
  ): Promise<any> {
    return Promise.all([
      previousButtonRef
        ? this.boundEditorSDK.components.properties.update({
            componentRef: previousButtonRef,
            props: { isHidden: false },
          })
        : Promise.resolve(),
      submitButtonRef
        ? this._transfromButtonToRole(stepComponentRef, submitButtonRef, nextButton)
        : Promise.resolve(),
    ])
  }

  private _fixFirstStep(
    stepComponentRef: ComponentRef,
    previousButtonRef: ComponentRef,
    submitButtonRef: ComponentRef,
    nextButton: ComponentStructre
  ) {
    return Promise.all([
      previousButtonRef
        ? this.boundEditorSDK.components.properties.update({
            componentRef: previousButtonRef,
            props: { isHidden: true },
          })
        : Promise.resolve(),
      submitButtonRef
        ? this._transfromButtonToRole(stepComponentRef, submitButtonRef, nextButton)
        : Promise.resolve(),
    ])
  }

  private _fixFirstLastStep(
    stepComponentRef: ComponentRef,
    previousButtonRef: ComponentRef,
    nextButtonRef: ComponentRef,
    submitButton: ComponentStructre
  ) {
    return Promise.all([
      previousButtonRef
        ? this.boundEditorSDK.components.properties.update({
            componentRef: previousButtonRef,
            props: { isHidden: true },
          })
        : Promise.resolve(),
      nextButtonRef
        ? this._transfromButtonToRole(stepComponentRef, nextButtonRef, submitButton)
        : Promise.resolve(),
    ])
  }

  private _fixLastStep(
    stepComponentRef: ComponentRef,
    previousButtonRef: ComponentRef,
    nextButtonRef: ComponentRef,
    submitButton: ComponentStructre
  ) {
    return Promise.all([
      previousButtonRef
        ? this.boundEditorSDK.components.properties.update({
            componentRef: previousButtonRef,
            props: { isHidden: false },
          })
        : Promise.resolve(),
      nextButtonRef
        ? this._transfromButtonToRole(stepComponentRef, nextButtonRef, submitButton)
        : Promise.resolve(),
    ])
  }

  private async _transfromButtonToRole(
    stepRef: ComponentRef,
    buttonRef: ComponentRef,
    destButton: ComponentStructre
  ) {
    const { x, y } = await this.boundEditorSDK.components.layout.get({ componentRef: buttonRef })
    return Promise.all([
      this.boundEditorSDK.components.add({
        pageRef: stepRef,
        componentDefinition: _.merge({}, destButton, { layout: { x, y } }),
      }),
      this.boundEditorSDK.components.remove({ componentRef: buttonRef }),
    ])
  }

  public async getSteps(stepsContainer: ComponentRef): Promise<StepData[]> {
    const children: ComponentRef[] = await this.boundEditorSDK.components.getChildren({
      componentRef: stepsContainer,
    })
    const stepsConnections = await this.boundEditorSDK.components.get({
      componentRefs: children,
      properties: ['connections'],
    })
    return _.compact(
      stepsConnections.map(child => {
        const {
          config: { title },
          role,
        } = _.find(child.connections, { isPrimary: true })
        if (_.eq(role, STEP_ROLE) || _.eq(role, LAST_STEP_ROLE)) {
          return { componentRef: child.componentRef, title, role }
        }
      })
    )
  }

  public updateStepTitle(step: ComponentRef, title): Promise<void> {
    return this.boundEditorSDK.application.sessionState.update({
      stateMap: { [step.id]: title },
    })
  }

  public async renameStep(currentStepRef, { label, id }, dropDownTitle): Promise<void> {
    await this.updateStepTitle(currentStepRef, dropDownTitle)
    await this.selectStep(currentStepRef, id)
    return this.coreApi.setComponentConnection(currentStepRef, { title: label })
  }

  private _updateStepsTitles(stepsData: StepData[]) {
    const numOfSortableSteps = stepsData.length - 1
    const stateTitlesMap = stepsData.reduce((acc, stepData, i) => {
      const title = isSortableStep(stepData)
        ? `${i + 1}/${numOfSortableSteps} - ${stepData.title}`
        : stepData.title
      acc[stepData.componentRef.id] = title
      return acc
    }, {})
    return this.boundEditorSDK.application.sessionState.update({
      stateMap: stateTitlesMap,
    })
  }

  public async updateMultiStepFormTitles(stepsContainerRef: ComponentRef) {
    const stepsData: StepData[] = await this.getSteps(stepsContainerRef)
    return this._updateStepsTitles(stepsData)
  }

  public async updateMultiStepFormsTitles(): Promise<void> {
    const controllers: ControllerRef[] = await this.boundEditorSDK.controllers.listAllControllers()
    await Promise.all(
      _.map(controllers, async ({ controllerRef }) => {
        const stepsContainers = await this.coreApi.findConnectedComponentsByRole(
          controllerRef,
          ROLE_FORM
        )
        if (hasValidConnectedStepsContainer(stepsContainers)) {
          return this.updateMultiStepFormTitles(_.first(stepsContainers))
        }
      })
    )
  }

  public async getCurrentStepRef(componentRef): Promise<ComponentRef> {
    const componentType = await this.boundEditorSDK.components.getType({ componentRef })
    if (componentType !== COMPONENT_TYPES.STATE_BOX) return null

    const currentState = await this.boundEditorSDK.components.behaviors.getRuntimeState({
      componentRef,
    })
    const children = await this.boundEditorSDK.components.getChildren({ componentRef })
    return children[currentState.currentIndex]
  }

  private async _getFormConnectionItem(
    stepsContainerRef: ComponentRef
  ): Promise<ComponentConnectionItem> {
    const formConnection = await this.coreApi.getComponentConnection(stepsContainerRef)
    const formData = await this.boundEditorSDK.components.data.get({
      componentRef: formConnection.controllerRef,
    })

    const formConnectionItem: ComponentConnectionItem = {
      type: 'ConnectionItem',
      role: formConnection.role,
      config: JSON.stringify(formConnection.config),
      isPrimary: formConnection.isPrimary,
      controllerId: formData.id,
    }
    return formConnectionItem
  }

  @undoable()
  public async deleteStep(
    stepsContainerRef: ComponentRef,
    stepsData: StepData[],
    deletedIndex: number,
  ): Promise<StepData[]> {
    const deletedStepRef = stepsData[deletedIndex].componentRef
    const deletedStepPosition = getStepPosition(
      deletedIndex,
      stepsData.length,
      stepsData[stepsData.length - 1].role
    )

    switch (deletedStepPosition) {
      case STEP_POSITION.LAST_STEP: {
        const formConnection = await this._getFormConnectionItem(stepsContainerRef)
        await this._makeRegularStepLastStep(
          stepsData[deletedIndex - 1].componentRef,
          formConnection
        )
        break
      }
      case STEP_POSITION.FIRST_STEP: {
        const formConnection = await this._getFormConnectionItem(stepsContainerRef)
        await this._makeRegularStepFirstStep(
          stepsData[deletedIndex + 1].componentRef,
          formConnection
        )
        break
      }
    }

    await this.boundEditorSDK.components.remove({ componentRef: deletedStepRef })
    await this.updateMultiStepFormTitles(stepsContainerRef)
    await this.selectStep(stepsContainerRef, deletedIndex ? deletedIndex - 1 : deletedIndex)

    return this.getSteps(stepsContainerRef)
  }

  public loadInitialPanelData(formComponentRef: ComponentRef) {
    return Promise.all([
      this.getSteps(formComponentRef),
      this.coreApi.premium.getPremiumRestrictions(),
    ]).then(([stepsData, { restrictions }]) => ({
      stepsData: stepsData,
      restrictions,
    }))
  }

  private async _getPreset(formConnection: ComponentConnectionItem) {
    const { preset: presetKey } = JSON.parse(formConnection.config)
    const locale = await this.boundEditorSDK.info.getLanguage()
    const rawPreset = await fetchPreset(presetKey, locale, reason =>
      this.coreApi.logFetchPresetsFailed(null, reason)
    )
    if (!rawPreset) {
      return
    }
    return convertPreset(rawPreset, {
      controllerId: formConnection.controllerId,
    })
  }

  private async _getNavigationButtonsFromPreset(
    formConnection: ComponentConnectionItem,
    rolesToSearch: string[]
  ) {
    const presetStructure = await this._getPreset(formConnection)
    if (!presetStructure) {
      return []
    }
    return rolesToSearch.map(role => getComponentByRole(presetStructure, role))
  }

  private _getNavigationButtonsFromStep(
    stepDefinition: ComponentStructre,
    rolesToSearch: string[]
  ) {
    return rolesToSearch.map(role => getComponentByRole(stepDefinition, role))
  }

  private async _getNavigationButtons(
    multiStepDefinition: ComponentStructre,
    rolesToSearch: string[]
  ): Promise<ComponentStructre[]> {
    const buttons = this._getNavigationButtonsFromStep(multiStepDefinition, rolesToSearch)
    if (buttons.filter(_.isNil).length) {
      const formConnection = findPrimmaryConnection(multiStepDefinition)
      const buttonsFromPreset = await this._getNavigationButtonsFromPreset(
        formConnection,
        rolesToSearch
      )
      return buttons.map((button, index) => button || buttonsFromPreset[index])
    } else {
      return buttons
    }
  }

  private async _getNavigationButtonsForNewStep(
    currentStepDefinition: ComponentStructre,
    formConnection: ComponentConnectionItem,
    isLastStep: boolean,
    stepHeight: number
  ): Promise<{
    leftButton: ComponentStructre
    rightButton: ComponentStructre
  }> {
    const rolesToSearch = [ROLE_PREVIOUS_BUTTON, isLastStep ? ROLE_SUBMIT_BUTTON : ROLE_NEXT_BUTTON]
    const showLeftButton = button => button && _.merge({}, button, { props: { isHidden: false } })

    const [leftButtonStep, rightButtonStep] = this._getNavigationButtonsFromStep(
      currentStepDefinition,
      rolesToSearch
    )
    if (leftButtonStep && rightButtonStep) {
      return {
        leftButton: showLeftButton(leftButtonStep),
        rightButton: rightButtonStep,
      }
    }

    const [
      leftButtonFromPreset,
      rightButtonFromPreset,
    ] = await this._getNavigationButtonsFromPreset(formConnection, rolesToSearch)

    return {
      rightButton: limitComponentInContainer(rightButtonFromPreset, stepHeight),
      leftButton: limitComponentInContainer(showLeftButton(leftButtonFromPreset), stepHeight),
    }
  }

  private async _createNewStepStructure(
    currentStepDefinition: ComponentStructre,
    formConnection: ComponentConnectionItem,
    isLastStep: boolean
  ): Promise<ComponentStructre> {
    const { leftButton, rightButton } = await this._getNavigationButtonsForNewStep(
      currentStepDefinition,
      formConnection,
      isLastStep,
      currentStepDefinition.layout.height
    )
    const stepWithNewName = connectComponentToConnection(currentStepDefinition, {
      role: STEP_ROLE,
      config: { title: translations.t('multiStepForm.newStepName') },
      controllerId: formConnection.controllerId,
    })

    return {
      ...stepWithNewName,
      components: [leftButton, rightButton],
    }
  }

  public async updateStepsButtonLabel(stateBoxRef, role, buttonLabel) {
    const steps: ComponentRef[] = await this.boundEditorSDK.components.getChildren({
      componentRef: stateBoxRef,
    })

    const buttons = _.flatten(
      await Promise.all(
        _.map(steps, stepRef => this.coreApi.findChildComponentsByRole(stepRef, role))
      )
    )

    return Promise.all(
      _.map(buttons, buttonRef =>
        this.boundEditorSDK.components.data.update({
          componentRef: buttonRef,
          data: { label: buttonLabel },
        })
      )
    )
  }

  private async _getNewStepIndexData(
    steps: StepData[],
    currentStepIndex: number
  ): Promise<{ stepIndex: number; stepToDuplicateIndex: number; isLastStep: boolean }> {
    const lastStepRole = steps[steps.length - 1].role
    const currentStepRole = steps[currentStepIndex].role
    const stepIndex = currentStepRole == LAST_STEP_ROLE ? currentStepIndex : currentStepIndex + 1
    const newLastStepIndex = steps.length
    const isLastStep =
      lastStepRole === LAST_STEP_ROLE
        ? stepIndex === newLastStepIndex - 1
        : stepIndex === newLastStepIndex
    return {
      stepIndex,
      stepToDuplicateIndex: stepIndex - 1,
      isLastStep,
    }
  }

  private async _getButtonStructureByRole(
    existingButtonRef: ComponentRef,
    buttonRole: string,
    formConnection: ComponentConnectionItem,
    coords: { x: number; y: number } | {}
  ): Promise<ComponentStructre> {
    let baseStructure
    if (existingButtonRef) {
      baseStructure = await this.boundEditorSDK.components.serialize({
        componentRef: existingButtonRef,
      })
    } else {
      const presetStructure = await this._getPreset(formConnection)
      baseStructure = getComponentByRole(presetStructure, buttonRole)
    }

    return _.merge({}, baseStructure, { layout: coords })
  }

  private async _makeRegularStepFirstStep(
    stepRef: ComponentRef,
    formConnection: ComponentConnectionItem
  ): Promise<void> {
    const [prevButtonRef] = await this.coreApi.findChildComponentsByRole(
      stepRef,
      ROLE_PREVIOUS_BUTTON
    )

    if (prevButtonRef) {
      return this.boundEditorSDK.components.properties.update({
        componentRef: prevButtonRef,
        props: { isHidden: true },
      })
    } else {
      const somePrevButtonRef = await this.coreApi.findComponentByRole(
        stepRef,
        ROLE_PREVIOUS_BUTTON
      )

      const prevButtonStructure = await this._getButtonStructureByRole(
        somePrevButtonRef,
        ROLE_PREVIOUS_BUTTON,
        formConnection,
        {}
      )

      const { x, y, extraHeightToStep } = await this._calcButtonLayout(
        stepRef,
        ROLE_PREVIOUS_BUTTON,
        prevButtonStructure
      )
      if (extraHeightToStep) {
        this.coreApi.addHeightToContainers(stepRef, extraHeightToStep)
      }

      _.merge(prevButtonStructure, { props: { isHidden: true }, layout: { x, y } })

      return this.boundEditorSDK.components.add({
        componentDefinition: prevButtonStructure,
        pageRef: stepRef,
      })
    }
  }

  private async _convertStepButton(
    stepRef: ComponentRef,
    formConnection: ComponentConnectionItem,
    { currentButtonRole, newButtonRole }
  ): Promise<void> {
    const [[currentButton], buttonToCopy] = await Promise.all([
      this.coreApi.findChildComponentsByRole(stepRef, currentButtonRole),
      this.coreApi.findComponentByRole(stepRef, newButtonRole),
    ])

    let newButtonCoords = {}
    if (currentButton) {
      const { x, y } = await this.boundEditorSDK.components.layout.get({
        componentRef: currentButton,
      })
      await this.boundEditorSDK.components.remove({ componentRef: currentButton })
      newButtonCoords = { x, y }
    }

    const newButtonStructure = await this._getButtonStructureByRole(
      buttonToCopy,
      newButtonRole,
      formConnection,
      newButtonCoords
    )

    if (_.isEmpty(newButtonCoords)) {
      const { x, y, extraHeightToStep } = await this._calcButtonLayout(
        stepRef,
        newButtonRole,
        newButtonStructure
      )
      _.merge(newButtonStructure, { layout: { x, y } })
      if (extraHeightToStep) {
        this.coreApi.addHeightToContainers(stepRef, extraHeightToStep)
      }
    }

    return this.boundEditorSDK.components.add({
      componentDefinition: newButtonStructure,
      pageRef: stepRef,
    })
  }

  private async _makeRegularStepLastStep(
    stepRef: ComponentRef,
    formConnection: ComponentConnectionItem
  ): Promise<void> {
    return this._convertStepButton(stepRef, formConnection, {
      currentButtonRole: ROLE_NEXT_BUTTON,
      newButtonRole: ROLE_SUBMIT_BUTTON,
    })
  }

  private async _makeLastStepRegularStep(
    lastStepRef: ComponentRef,
    formConnection: ComponentConnectionItem
  ): Promise<void> {
    return this._convertStepButton(lastStepRef, formConnection, {
      currentButtonRole: ROLE_SUBMIT_BUTTON,
      newButtonRole: ROLE_NEXT_BUTTON,
    })
  }

  @undoable()
  public async addNewStep(
    formComponentRef: ComponentRef,
    stepsData: StepData[]
  ): Promise<StepData[]> {
    const [{ currentIndex }, currentMultiStepDefinition] = await Promise.all([
      this.boundEditorSDK.components.behaviors.getRuntimeState({
        componentRef: formComponentRef,
      }),
      this.boundEditorSDK.components.serialize({
        componentRef: formComponentRef,
      }),
    ])
    const { stepIndex, stepToDuplicateIndex, isLastStep } = await this._getNewStepIndexData(
      stepsData,
      currentIndex
    )
    const formConnection = findPrimmaryConnection(currentMultiStepDefinition)
    const stepStructure = await this._createNewStepStructure(
      currentMultiStepDefinition.components[stepToDuplicateIndex],
      formConnection,
      isLastStep
    )
    await this.boundEditorSDK.components.add({
      pageRef: formComponentRef,
      componentDefinition: stepStructure,
      optionalIndex: stepIndex,
    })

    await Promise.all([
      this.updateMultiStepFormTitles(formComponentRef),
      this.selectStep(formComponentRef, stepIndex),
      isLastStep
        ? this._makeLastStepRegularStep(
            stepsData[stepToDuplicateIndex].componentRef,
            formConnection
          )
        : Promise.resolve(),
    ])

    this.selectStep(formComponentRef, stepIndex)

    return this.getSteps(formComponentRef)
  }

  private async _calcButtonLayout(
    stepRef: ComponentRef,
    buttonRole: string,
    buttonStructure: ComponentStructre
  ): Promise<{ x: number; y: number; extraHeightToStep: number }> {
    const childLayouts = await this.coreApi.layout.getChildrenLayouts(stepRef, null, true)
    let buttonY
    if (!_.isEmpty(childLayouts)) {
      const navigationButton = _.find(childLayouts, element => isNavigationButton(element.role))
      if (navigationButton) buttonY = navigationButton.y
      else {
        const lastLayout = _.maxBy(childLayouts, (field: any) => field.y + field.height)
        buttonY = lastLayout.y + lastLayout.height + SPACE_BETWEEN_FIELDS
      }
    } else {
      buttonY = SPACE_BETWEEN_FIELDS
    }
    const stepLayout = await this.boundEditorSDK.components.layout.get({ componentRef: stepRef })
    const heightLeftInStep = stepLayout.height - (buttonY + buttonStructure.layout.height)
    const extraHeightToStep = heightLeftInStep < 0 ? -heightLeftInStep : 0

    if (_.eq(buttonRole, ROLE_PREVIOUS_BUTTON)) {
      return { x: MULTI_STEP_BUTTON_SIDE_MARGIN, y: buttonY, extraHeightToStep }
    }

    if (_.eq(buttonRole, ROLE_NEXT_BUTTON) || _.eq(buttonRole, ROLE_SUBMIT_BUTTON)) {
      return {
        x: Math.max(
          stepLayout.width - MULTI_STEP_BUTTON_SIDE_MARGIN - buttonStructure.layout.width,
          0
        ),
        y: buttonY,
        extraHeightToStep,
      }
    }

    return null
  }
}
