import * as _ from 'lodash'
import {
  FieldProperties,
  getFieldProperties,
  getFieldPluginsExtraData,
  FieldExtraData,
} from './fields/field-types-data'
import * as fields from '../../../assets/presets/fields.json'
import { Theme } from '../../../constants/form-style'
import { FieldPreset } from '../../../constants/field-types'
import { fetchRetry } from '../../../utils/fetch-utils'
import { PRESET_STATIC_URL, THEMES_STATIC_URL } from '../../../constants/resources'
import { FormPlugin } from '../../../constants/plugins'

const applyFieldData = (
  field,
  fieldType: FieldPreset,
  extraData: any = {},
  pluginsExtraData = {}
) => {
  const fieldProps: FieldProperties = getFieldProperties(fieldType)
  return _.merge({}, field, fieldProps.extraData, extraData, pluginsExtraData, {
    connectionConfig: {
      fieldType,
      collectionFieldType: fieldProps.collectionFieldType,
    },
  })
}

const presets: { [key: string]: Promise<Response> } = {}

export const addPreset = (presetKey: string, presetValue) => {
  presets[presetKey] = presetValue
}

export const getFormPreset = async (presetKey: string, locale = 'en') => {
  return await presets[presetKey] || await fetchFormPreset(presetKey, locale)
}

export const fetchFormPreset = async (presetKey, locale) => {
  const response = await fetchRetry(`${PRESET_STATIC_URL}/${presetKey}/${presetKey}.preset.${locale}.json`)
  addPreset(presetKey, response.clone().json())
  return response.json()
}

export const getFieldPreset = ({
                                 fieldType,
                                 extraData,
                                 plugins,
                               }: {
  fieldType: FieldPreset
  extraData: Partial<FieldExtraData>
  plugins: FormPlugin[]
}) => {
  const componentType = getFieldProperties(fieldType).componentType
  const pluginsExtraData = getFieldPluginsExtraData({ fieldType, plugins })
  return applyFieldData(fields[componentType], fieldType, extraData, pluginsExtraData)
}

export const getGlobalDesignPresets = () => {
  return {
    [Theme.PRESET01]: {
      src: `${THEMES_STATIC_URL}/preset01.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET02]: {
      src: `${THEMES_STATIC_URL}/preset02.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET03]: {
      src: `${THEMES_STATIC_URL}/preset03.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET04]: {
      src: `${THEMES_STATIC_URL}/preset04.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET05]: {
      src: `${THEMES_STATIC_URL}/preset05.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET06]: {
      src: `${THEMES_STATIC_URL}/preset06.png`,
      layout: { width: '112px', height: '83px' },
    },
    [Theme.PRESET07]: {
      src: `${THEMES_STATIC_URL}/preset07.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET08]: {
      src: `${THEMES_STATIC_URL}/preset08.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET09]: {
      src: `${THEMES_STATIC_URL}/preset09.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET10]: {
      src: `${THEMES_STATIC_URL}/preset10.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET11]: {
      src: `${THEMES_STATIC_URL}/preset11.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET12]: {
      src: `${THEMES_STATIC_URL}/preset12.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET13]: {
      src: `${THEMES_STATIC_URL}/preset13.png`,
      layout: { width: '112px', height: '83px' },
    },
    [Theme.PRESET14]: {
      src: `${THEMES_STATIC_URL}/preset14.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET15]: {
      src: `${THEMES_STATIC_URL}/preset15.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET16]: {
      src: `${THEMES_STATIC_URL}/preset16.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET17]: {
      src: `${THEMES_STATIC_URL}/preset17.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET18]: {
      src: `${THEMES_STATIC_URL}/preset18.png`,
      layout: { width: '112px', height: '83px' },
    },
    [Theme.PRESET19]: {
      src: `${THEMES_STATIC_URL}/preset19.png`,
      layout: { width: '111px', height: '83px' },
    },
    [Theme.PRESET20]: {
      src: `${THEMES_STATIC_URL}/preset20.png`,
      layout: { width: '111px', height: '83px' },
    },
  }
}
