import { FieldPreset } from '../../constants/field-types'
import { SuccessActionTypes } from '../../constants/success-settings'
import { FormPreset, FormPresetType } from '../../constants/form-types'
import { FormPlugin } from '../../constants/plugins'
import { Theme } from '../../constants/form-style'
import { COMPONENT_TYPES } from './preset/fields/component-types'

export type FieldPlaceholder = string | { text: string; value: any }

export enum FileType {
  IMAGE = 'Image',
  DOCUMNET = 'Document',
}

export enum OptionType {
  SELECT_OPTION = 'SelectOption',
  RADIO_BUTTON = 'RadioButton',
  CHECKBOX_GROUP = 'CheckboxInput',
}

export interface RadioOption {
  label: string
  value: any
  type: OptionType.RADIO_BUTTON
}

export interface CheckboxOption {
  label: string
  value: any
  checked: boolean
  type: OptionType.CHECKBOX_GROUP
}

export interface DropdownOption {
  value: any
  text: string
  description: string
  disabled: boolean
  type: OptionType.SELECT_OPTION
}

export type FieldOption = RadioOption | DropdownOption | CheckboxOption

export interface ContactLabel {
  id: string
  name: string
  userDefined: boolean
  contactsCount: number
}

export interface FormField {
  componentRef: ComponentRef
  crmLabel?: string
  required: boolean
  crmType?: string
  crmTag?: string
  fieldType: FieldPreset
  customFieldId?: string
  collectionFieldKey?: string
  collectionFieldType?: string
  checked?: boolean
  role: string
  label: string
  placeholder: FieldPlaceholder
  showLabel: boolean
  buttonLabel?: string
  x?: number
  y?: number
  height?: number
  width?: number
  dateFormat?: string
  options?: FieldOption[]
  defaultValue?: string
  filesType?: FileType
  showPlaceholder?: boolean
  loading?: boolean
  componentType: COMPONENT_TYPES
  id?: string
}

export interface FormFieldDefinition {
  fieldId: string
  fieldName: string
  fieldCollectionType?: string
  fieldCollectionKey?: string
}

export type ComponentRef = {
  id: string
  type: string
}

export type ControllerRef = {
  controllerRef: ComponentRef
}

export type formComponent = {
  presetKey?: string
  rawComp: any
  theme?: Theme
  newMessage?: string
}

export type Plugin = {
  id: FormPlugin
  payload?: any
}

export interface ProductData {
  productId: string
  name: string
  price: string
}

export type ComponentConfig = {
  formName?: string
  preset?: FormPreset
  fieldType?: FieldPreset
  plugins?: Plugin[]
  collectionId?: string
  successActionType?: SuccessActionTypes
  labels?: string[]
  successLinkValue?: any
  theme?: Theme
  emailId?: string
  secondEmailId?: string
  formLabelId?: string
  collectionFieldKey?: string
  crmLabel?: string
  crmType?: string
  crmTag?: string
  customFieldId?: string
  collectionFieldType?: string
  label?: string
  product?: ProductData
  errorMessage?: string
  secondsToResetForm?: number
  presetType?: FormPresetType
  msid?: string
  title?: string
}

export type ComponentConnection = {
  role: string
  config: ComponentConfig
  controllerRef: ComponentRef
  isPrimary: boolean
}

export type ComponentConnectionsList = {
  type: 'ConnectionList'
  items: ComponentConnectionItem[]
  metaData: { isPreset: false; schemaVersion: '1.0'; isHidden: false }
}

export type ComponentConnectionItem = {
  type: 'ConnectionItem'
  role: string
  config: string
  isPrimary: boolean
  controllerId: string
}

export type ColorPalette = {
  colorsPalette: Array<Array<string>>
  colors: object
}

export type FormSnapshot = {
  components: ComponentSnapshot[]
  formComponent?: FormRefSnapshot
}

export type ComponentSnapshot = {
  role: string
  config: ComponentConfig
  data: any
  props: any
  layout: any
  componentRef: ComponentRef
  style: any
  componentType: COMPONENT_TYPES
  components: ComponentSnapshot[] | undefined
  skin: string
}

export type FormRefSnapshot = {
  componentRef: ComponentRef
  config: ComponentConfig
  layout: { width: number; height: number }
}

export interface FieldLayout {
  x: number
  y: number
  width: number
  height: number
}

export type Position = {
  x: number
  y: number
}

export type Message = {
  text: string
  position?: Position
}

export type Product = {
  id: string
  name: string
  price: string
  quantity?: number
}

export type StepData = {
  componentRef: ComponentRef
  title: string
  role: string
}

export enum ControllerType {
  REGISTRATION_FORM = 'registrationForm',
  GET_SUBSCRIBERS = 'getSubscribers',
  WIX_FORMS = 'wixForms',
  MULTI_STEP_FORM = 'multiStepForm',
}

export interface RawComponentStructre {
  role: string
  config: ComponentConfig
  components?: RawComponentStructre[]
  layout: FieldLayout
  style: ComponentStyle | string
  type: COMPONENT_TYPES
  props?: any
  data?: any
}

export interface ComponentStructre {
  components?: ComponentStructre[]
  layout: FieldLayout
  style?: ComponentStyle | string
  type: COMPONENT_TYPES
  props?: any
  data?: any
  connections: ComponentConnectionsList
  design?: any
}

interface ComponentStyle {
  type: String
  id: String
  metaData?: {
    isPreset: boolean
    schemaVersion: string
    isHidden: boolean
  }
  style?: {
    properties: any
    propertiesSource: any
    groups?: {}
  }
  componentClassName?: string
  pageId?: string
  compId?: string
  styleType?: string
  skin: string
}
