import { ONE_STEPS_CONTAINER, STEP_POSITION } from './constants'
import { StepData, ComponentRef, ComponentStructre } from '../api-types'
import * as _ from 'lodash'
import {
  ROLE_NEXT_BUTTON,
  ROLE_PREVIOUS_BUTTON,
  ROLE_SUBMIT_BUTTON,
  STEP_ROLE,
  LAST_STEP_ROLE,
} from '../../../constants/roles'

export const isSortableStep = (stepData: StepData) => {
  return _.eq(stepData.role, STEP_ROLE)
}

export const hasValidConnectedStepsContainer = (stepsContainer: Array<any>) => {
  return stepsContainer.length === ONE_STEPS_CONTAINER
}

export const getStepPosition = (stepIndex, numberOfSteps, lastStepRole) => {
  const lastStepPosition = lastStepRole === LAST_STEP_ROLE ? numberOfSteps - 2 : numberOfSteps - 1
  if (stepIndex === 0 && stepIndex === lastStepPosition) {
    return STEP_POSITION.FIRST_AND_LAST_STEP
  }
  if (stepIndex === 0) {
    return STEP_POSITION.FIRST_STEP
  }
  if (stepIndex === lastStepPosition) {
    return STEP_POSITION.LAST_STEP
  }
  return STEP_POSITION.OTHER
}

export const getReorderedSteps = (
  steps: StepData[],
  srcIndex: number,
  destIndex: number,
  currentStepRef: ComponentRef
): { currentStep: number; stepsData: StepData[] } => {
  const stepsData = _.cloneDeep(steps)
  stepsData.splice(destIndex, 0, stepsData.splice(srcIndex, 1)[0])
  return { currentStep: _.findIndex(stepsData, { componentRef: currentStepRef }), stepsData }
}

export const findPrimmaryConnection = (componentDefinition: ComponentStructre) =>
  _.find(componentDefinition.connections.items, {
    isPrimary: true,
  })

export const isNavigationButton = (role: string): boolean => {
  return role === ROLE_PREVIOUS_BUTTON || role === ROLE_NEXT_BUTTON || role === ROLE_SUBMIT_BUTTON
}
