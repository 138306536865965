import * as _ from 'lodash'
import { safelyStringify, serializeError } from '../../utils/utils'
import { INPUT_FIELDS_PREFIX, EDITABLE_INPUT_FIELDS } from '../../constants/roles'
import { ComponentRef, ComponentSnapshot } from './api-types'

export const undoable = () => (_target, _funcName, decorator) => {
  const originalMethod = decorator.value
  decorator.value = async function(...args) {
    const res = await originalMethod.apply(this, args)
    await this.boundEditorSDK.history.add({ label: 'History' })
    return res
  }
}

const promiseQueue = []

export const dequeue = () => {
  promiseQueue.splice(0, 1)
}

export const withSync = () => (_target, _funcName, decorator) => {
  const originalMethod = decorator.value
  decorator.value = function(...args) {
    let promise
    const applyMethod = () => originalMethod.apply(this, args)
    if (promiseQueue.length === 0) {
      promise = applyMethod()
    } else {
      promise = promiseQueue[promiseQueue.length - 1].then(applyMethod).catch(applyMethod)
    }
    promiseQueue.push(promise.then(dequeue).catch(dequeue))
    return promise
  }
}

export const withBi = ({ startEvid = {}, endEvid = {} } = {}) => (
  _target,
  _funcName,
  decorator
) => {
  const isBiData = arg => arg.startBi !== undefined || arg.endBi !== undefined
  const originalMethod = decorator.value

  decorator.value = async function(...args) {
    let res
    const biData = args[args.length - 1]

    if (isBiData(biData)) {
      if (!_.isEmpty(biData.startBi)) {
        this.biLogger.log({ evid: startEvid, ...biData.startBi })
      }
      res = await originalMethod.apply(this, args.slice(0, -1))
      if (!_.isEmpty(biData.endBi)) {
        this.biLogger.log({ evid: endEvid, ...biData.endBi })
      }
    } else {
      res = await originalMethod.apply(this, args)
    }

    return res
  }
}

export const wrapPublicApi = (
  f,
  funcName,
  ravenInstance,
  { absorbException = true, includeArgs = true } = {}
): Function => {
  return (...args) => {
    const stringifiedArgs = safelyStringify(args)
    ravenInstance.captureBreadcrumb({
      message: `[core-api] '${funcName}'`,
      category: 'core-api',
      data: {
        name: funcName,
        ...(includeArgs ? { args: stringifiedArgs } : {}),
      },
      level: 'info',
    })
    return f(...args).catch(err => {
      console.error(err)
      ravenInstance.captureException(err, {
        tags: { 'core-api': funcName },
        extra: {
          args: stringifiedArgs,
          error: serializeError(err),
        },
      })
      if (absorbException) {
        return null
      } else {
        throw err
      }
    })
  }
}

const generateRuntimeApi = (
  obj,
  startObject,
  ravenInstance,
  funcNameTransformer = funcName => funcName
) => {
  return _.reduce(
    Object.getOwnPropertyNames(Object.getPrototypeOf(obj)),
    (apiObj, funcName) => {
      const f = obj[funcName].bind(obj)
      return _.merge(apiObj, {
        [funcNameTransformer(funcName)]: _.startsWith(funcName, '_')
          ? f
          : wrapPublicApi(f, funcName, ravenInstance),
      })
    },
    startObject
  )
}

const generateRuntimeApis = (apis, ravenInstance) => {
  const toPublicApi = apiName => {
    const funcNameTransformer = funcName => `${apiName}.${funcName}`
    return generateRuntimeApi(apis[apiName], {}, ravenInstance, funcNameTransformer)
  }

  const apisNames = Object.keys(apis)
  const apisFunctions = apisNames.map(toPublicApi)

  return _.assign({}, ...apisFunctions)
}

export const generateRuntimeCoreApi = (coreApi, apis, ravenInstance) => {
  const runtimeApis = generateRuntimeApis(apis, ravenInstance)
  return generateRuntimeApi(coreApi, runtimeApis, ravenInstance)
}

export const isInputField = (role: string) =>
  isAnyField(role) && EDITABLE_INPUT_FIELDS.includes(role)

export const isAnyField = (role: string) => _.startsWith(role, INPUT_FIELDS_PREFIX)

export const getValidCollectionId = (formId: string, collectionId: string) => {
  if (!collectionId) return

  const [compId, realCollectionId] = collectionId.split('_')
  return !collectionId.startsWith('comp')
    ? collectionId
    : compId === formId
    ? realCollectionId
    : undefined
}

export const compareObjectsAndUpdateIfNeeded = (
  componentRef: ComponentRef,
  currValue: any,
  prevValue: any,
  onUpdateCallback: (comp: ComponentRef, updates: any) => any
) => {
  const valueToUpdate = _.reduce(
    prevValue,
    (prev, curr, key) => {
      if (!_.isEqual(curr, currValue[key])) {
        prev[key] = curr
      }
      return prev
    },
    {}
  )
  if (_.isEmpty(valueToUpdate)) {
    return
  }
  return onUpdateCallback(componentRef, valueToUpdate)
}

export const componentsDiff = (currComps: ComponentSnapshot[], prevComps: ComponentSnapshot[]) => {
  const currRefs: ComponentRef[] = currComps.map(c => c.componentRef)
  const prevRefs: ComponentRef[] = prevComps.map(c => c.componentRef)

  const componentsRefsToAdd = <ComponentRef[]>_.differenceWith(prevRefs, currRefs, _.isEqual)
  const componentsRefsToDelete = <ComponentRef[]>_.differenceWith(currRefs, prevRefs, _.isEqual)

  return {
    componentsToDelete: componentsRefsToDelete,
    componentsToAdd: prevComps.filter(comp => _.some(componentsRefsToAdd, comp.componentRef)),
  }
}

export const getPrimaryConnection = connections => _.find(connections, ['isPrimary', true]) || {}
