import * as _ from 'lodash'
import { DESIGN_GROUPS, getGroupType, roleDesignMapping } from '../manifests/global-design-manifest'
import { FieldPreset } from '../../../constants/field-types'
import { getFieldProperties } from '../preset/fields/field-types-data'

export const PARAM_TYPE_DEFAULT_VALUE = {
  ALPHA: 1,
  SHADOW: 'false',
  BG_COLOR_ALPHA: '#ffffff',
  BORDER_COLOR_ALPHA: '#e3e3e3',
  BORDER_SIZES: '1px',
  BORDER_RADIUS: 0,
  TEXT_COLOR: '#000000',
  FONT: 'font_8',
  BOX_SHADOW: '0 0 0 rgba(0, 0, 0, 0)'
}

const PARAMS_ALPHA = {
  'bg-calendar': 1,
}

const PARAMS_DEFAULT_VALUES = {
  'bg-day-selected': 'color_12',
  'txt-day': '#ffffff',
  'txt-header': '#ffffff',
}

export type designMapping = { [key in DESIGN_GROUPS]?: string[] }
export type commonStyles = { [key in DESIGN_GROUPS]?: { value: string | number; alpha?: number, isToggleOn?: string } }

const calcCommonValueInArray = a => {
  const frequency = {}
  let max = 0
  let result: string
  for (let v in a) {
    frequency[a[v]] = (frequency[a[v]] || 0) + 1
    if (frequency[a[v]] > max) {
      max = frequency[a[v]]
      result = a[v]
    }
  }
  return result
}

const calcSecondMostCommonValueInArray = a => {
  const commonValue = calcCommonValueInArray(a)
  const arrayWithoutCommonValue = _.filter(a, value => value !== commonValue)
  if (arrayWithoutCommonValue.length) {
    return calcCommonValueInArray(arrayWithoutCommonValue)
  }

  return commonValue
}

const getGroupValuesFromStyle = (
  group: DESIGN_GROUPS,
  style: any,
  designMapping: designMapping,
  groupType: string,
  prefix?: string
) => {
  const mappedStyleParams = designMapping[group]
  return !mappedStyleParams
    ? [PARAM_TYPE_DEFAULT_VALUE[groupType]]
    : mappedStyleParams.map(p => style[`${prefix}${p}`] || PARAM_TYPE_DEFAULT_VALUE[groupType])
}

const getGroupValuesFromStyles = (
  group: DESIGN_GROUPS,
  fields: { style: any; designMapping: designMapping }[],
  groupType: string,
  prefix: string = ''
) => {
  return _.flatMap(fields, ({ style, designMapping }) =>
    getGroupValuesFromStyle(group, style, designMapping, groupType, prefix)
  )
}

const handleEqualBackgroundAndText = (
  commonStyles: commonStyles,
  backgroundGroup: DESIGN_GROUPS,
  fields
) => {
  const backgroundColor = commonStyles[backgroundGroup].value
  if (
    commonStyles[backgroundGroup].alpha === 1 &&
    (backgroundColor === commonStyles[DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR].value ||
      backgroundColor === commonStyles[DESIGN_GROUPS.MAIN_TEXT_COLOR].value)
  ) {
    const groupValues = getGroupValuesFromStyles(backgroundGroup, fields, 'BG_COLOR_ALPHA')
    const groupValue = calcSecondMostCommonValueInArray(groupValues)
    commonStyles[backgroundGroup] = {
      ...commonStyles[backgroundGroup],
      value: groupValue,
    }
  }
  return commonStyles
}

const handleEqualBackgroundsAndText = (commonStyles: commonStyles, fields) => {
  ;[
    DESIGN_GROUPS.INPUT_BACKGROUND,
    DESIGN_GROUPS.INPUT_BACKGROUND_ERROR,
    DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS,
    DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS,
    DESIGN_GROUPS.INPUT_BACKGROUND_HOVER,
  ].forEach(group => {
    commonStyles = handleEqualBackgroundAndText(commonStyles, group, fields)
  })
  return commonStyles
}

export const getGroupValue = (
  group: DESIGN_GROUPS,
  fields: { style: any; designMapping: designMapping }[]
) => {
  const groupType = getGroupType(group)
  const groupValues = getGroupValuesFromStyles(group, fields, groupType)
  const groupValue = calcCommonValueInArray(groupValues)

  if (_.includes(groupType, 'ALPHA')) {
    const alphaValues = getGroupValuesFromStyles(group, fields, 'ALPHA', 'alpha-')
    const alphaValue = calcCommonValueInArray(alphaValues)
    return { value: groupValue, alpha: alphaValue }
  }

  if (_.includes(groupType, 'SHADOW')) {
    const boxShadowToggleOnValues = getGroupValuesFromStyles(group, fields, 'SHADOW', 'boxShadowToggleOn-')
    const boxShadowToggleOnValue = calcCommonValueInArray(boxShadowToggleOnValues)
    return { value: groupValue, isToggleOn: boxShadowToggleOnValue }
  }

  return { value: groupValue }
}

export const calcCommonStyleGlobalDesign = (
  fields: { style: any; designMapping: designMapping }[]
): commonStyles => {
  const commonStyles = _.reduce(
    DESIGN_GROUPS,
    (acc, group) => {
      acc[group] = getGroupValue(group, fields)
      return acc
    },
    {}
  )
  return handleEqualBackgroundsAndText(commonStyles, fields)
}

const dateHasBadContrast = (param, commonStyles: commonStyles) =>
  (param === 'bg-day-selected' &&
    commonStyles[DESIGN_GROUPS.INPUT_BORDER_COLOR].value ===
      commonStyles[DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR].value) ||
  ((param === 'txt-day' || param === 'txt-header') &&
    commonStyles[DESIGN_GROUPS.INPUT_BACKGROUND].value ===
      commonStyles[DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR].value)

export const getFieldStyle = (commonStyles: commonStyles, fieldType: FieldPreset) => {
  const {
    componentType,
    extraData: { role },
  } = getFieldProperties(fieldType)
  const designMapping = _.get(roleDesignMapping, [role, componentType])
  if (!designMapping) return {}

  return _.reduce(
    designMapping,
    (acc, styleParams, group: DESIGN_GROUPS) => {
      styleParams.forEach(param => {
        const groupValue = commonStyles[group]
        if (!groupValue) return
        if (_.includes(getGroupType(group), 'ALPHA')) {
          acc[`alpha-${param}`] = PARAMS_ALPHA[param] || groupValue.alpha
        }
        if (_.includes(getGroupType(group), 'SHADOW')) {
          acc[`boxShadowToggleOn-${param}`] = groupValue.isToggleOn
        }
        acc[param] = dateHasBadContrast(param, commonStyles)
          ? PARAMS_DEFAULT_VALUES[param]
          : groupValue.value
      })
      return acc
    },
    {}
  )
}
