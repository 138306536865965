export enum FormPreset {
  CONTACT_FORM = 'contact-form',
  GET_SUBSCRIBERS = 'get-subscribers',
  POTENTIAL_CUSTOMERS = 'potential-customers',
  SALES_LEAD = 'sales-lead',
  SUPPORT_REQUEST = 'support-request',
  TESTIMONIAL = 'testimonial',
  JOB_APPLICATION = 'job-application',
  CUSTOMER_SATISFACTION = 'customer-satisfaction',
  PRICE_QUOTE = 'price-quote',
  CONTEST_ENTRY = 'contest-entry',
  QUIZ = 'quiz',
  REGISTRATION_FORM = 'registration-form',
  MULTI_STEP_FORM = 'state-box-mock'
}

export enum FormPresetType {
  PAYMENT = 'payment'
}
