import * as _ from 'lodash'
import {
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
  FIELDS,
} from '../../../constants/roles'
import { getFieldPreset, getFormPreset } from '../preset/preset-service'
import { getFieldStyle, commonStyles } from './form-style-service'
import translations from '../../../utils/translations'
import { escapeRegExp, innerText } from '../../../utils/utils'
import { FormPreset } from '../../../constants/form-types'
import { FieldPreset } from '../../../constants/field-types'
import {
  formComponent,
  FormSnapshot,
  ComponentStructre,
  RawComponentStructre,
  ComponentConfig,
  ControllerType,
} from '../api-types'
import { FormPlugin } from '../../../constants/plugins'

const getComponentFromPreset = async ({ role, preset, locale }, onFailedPresetCallback) => {
  if (!preset) return
  const rawPreset = await fetchPreset(preset, locale, onFailedPresetCallback)
  if (!rawPreset) return
  const components = rawPreset.components
  const roleSchema = _recursiveFindComponentSchema(components, role)
  return roleSchema
}

const _recursiveFindComponentSchema = (components, role) => {
  for (let i = 0; i < components.length; i++) {
    const comp = components[i]
    if (comp.role == role) {
      return comp
    } else if (comp.components) {
      const subcomp = _recursiveFindComponentSchema(comp.components, role)
      if (subcomp) {
        return subcomp
      }
    }
  }
}

const convertToInnerStructure = ({ role, connectionConfig, ...rest }: any) => ({
  role,
  connectionConfig,
  data: rest,
})

export const getExtraMessageText = ({ data, presetKey = '', newMessage }) => {
  const parsedMessage = innerText(data.text)
  return {
    text: data.text.replace(
      new RegExp(`>${escapeRegExp(innerText(data.text))}`),
      `>${newMessage ||
        (presetKey === FormPreset.REGISTRATION_FORM
          ? translations.t('settings.errorMessage.registrationForm')
          : parsedMessage)}`
    ),
  }
}

const getChildComponents = (presetKey, comp) =>
  comp.components &&
  comp.components.map(childComp => deConstructComponent({ presetKey, rawComp: childComp }))

const deConstructComponent = ({ presetKey, rawComp, newMessage = null }: formComponent) => {
  const comp = rawComp
  comp.connectionConfig = _.merge({}, comp.config, comp.connectionConfig)
  if (comp.role === ROLE_MESSAGE || comp.role === ROLE_DOWNLOAD_MESSAGE) {
    comp.data = _.merge(
      {},
      comp.data,
      getExtraMessageText({ data: comp.data, presetKey, newMessage })
    )
  }
  comp.components = getChildComponents(presetKey, comp)
  return comp
}

export const fetchPreset = async (
  presetKey: FormPreset,
  locale: string = 'en',
  onFailedPresetCallback: Function = _.noop
): Promise<RawComponentStructre | undefined> => {
  let rawPreset
  try {
    rawPreset = await getFormPreset(presetKey, locale)
  } catch (e) {
    await onFailedPresetCallback(`${presetKey}: ${(<Error>e).message}`)
    return
  }
  return rawPreset
}

// return { role, connectionConfig, data }
export const createField = ({
  preset,
  fieldType,
  extraData,
  commonStyles,
  formWidth,
  layout,
  plugins,
}: {
  preset: string
  fieldType: FieldPreset
  extraData: any
  commonStyles: commonStyles
  formWidth: number
  layout: any
  plugins: FormPlugin[]
}) => {
  // TODO remove presetKey
  const rawPreset = getFieldPreset({ fieldType, extraData, plugins })
  const width = Math.min(formWidth - layout.x, layout.width || rawPreset.layout.width)
  const fieldComponent = _.merge(
    {},
    deConstructComponent({ presetKey: preset, rawComp: rawPreset }),
    {
      layout: { ...layout, width },
    }
  )
  const fieldStyle = getFieldStyle(commonStyles, fieldType)
  _.assign(fieldComponent.style.style.properties, fieldStyle)
  return convertToInnerStructure(fieldComponent)
}

const restoreFieldSchema = async (
  { role, preset, locale, fallbackSchema },
  onFailedCallback
): Promise<{ rawSchema; fallback }> => {
  const rawSchema = await getComponentFromPreset(
    {
      role: role,
      preset,
      locale,
    },
    onFailedCallback
  )

  if (rawSchema) {
    return { rawSchema, fallback: false }
  } else {
    return { rawSchema: fallbackSchema, fallback: true }
  }
}

export const fetchSubmitButtonSchema = async (
  { label, preset, locale, fallbackSchema },
  onFailedCallback
) => {
  const { rawSchema, fallback } = await restoreFieldSchema(
    { role: ROLE_SUBMIT_BUTTON, preset, locale, fallbackSchema },
    onFailedCallback
  )

  const extraData = fallback
    ? {
        data: {
          label,
        },
      }
    : {}

  const buttonComponent = _.merge({}, deConstructComponent({ rawComp: rawSchema }), extraData)

  return convertToInnerStructure(buttonComponent)
}

export const fetchLoginLinkSchema = async (
  { label, preset, locale, fallbackSchema },
  onFailedCallback
) => {
  const { rawSchema, fallback } = await restoreFieldSchema(
    {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
      preset,
      locale,
      fallbackSchema,
    },
    onFailedCallback
  )

  const extraData = fallback
    ? {
        data: {
          label,
        },
      }
    : {}

  const loginLinkComponent = _.merge({}, deConstructComponent({ rawComp: rawSchema }), extraData)

  return convertToInnerStructure(loginLinkComponent)
}

export const fetchHiddenMessage = async (
  {
    newMessage,
    formLayout,
    preset,
    locale,
    fallbackSchema,
    role = ROLE_SUBMIT_BUTTON,
  }: {
    newMessage?
    formLayout
    preset
    locale
    fallbackSchema
    role
  },
  onFailedCallback
) => {
  const { rawSchema } = await restoreFieldSchema(
    { role, preset, locale, fallbackSchema },
    onFailedCallback
  )

  const isCenterAligned =
    rawSchema.data.text.match(/text-align:[\s]*center/) && rawSchema.layout.x === 0
  const messageWidth = isCenterAligned ? formLayout.width : rawSchema.layout.width

  rawSchema.role = role

  const hiddenMessageComponent = _.merge(
    {},
    deConstructComponent({
      rawComp: rawSchema,
      newMessage,
    }),
    { layout: { width: messageWidth } }
  )

  return convertToInnerStructure(hiddenMessageComponent)
}

export const convertPreset = (
  structure: RawComponentStructre,
  { controllerId, coords = {}, appWidgetStructre = null }
): ComponentStructre => {
  const presetLayout = _.merge({}, structure.layout, coords)
  const rootComponent = connectComponents(
    {
      ...structure,
      layout: appWidgetStructre ? _.merge({}, presetLayout, { x: 0, y: 0 }) : presetLayout,
    },
    controllerId
  )

  return appWidgetStructre
    ? {
        ...appWidgetStructre,
        data: { ...appWidgetStructre.data, id: controllerId },
        layout: presetLayout,
        style: 'appWidget1',
        components: [rootComponent],
      }
    : rootComponent
}

const connectComponents = (componentStructure: RawComponentStructre, controllerId: string) => {
  const convertedComponent = connectComponent(componentStructure, controllerId)

  if (!convertedComponent.components) {
    return convertedComponent
  }

  return {
    ...convertedComponent,
    components: convertedComponent.components.map(c => connectComponents(c, controllerId)),
  }
}

export const connectComponent = (
  componentStructure: RawComponentStructre,
  controllerId: string
) => ({
  ..._.omit(componentStructure, ['role', 'config']),
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'ConnectionItem',
        role: componentStructure.role,
        isPrimary: true,
        config: JSON.stringify(componentStructure.config),
        controllerId,
      },
    ],
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
  },
})

export const enhanceConfigByRole = async (
  structure: RawComponentStructre,
  produceConfigMap: { [key: string]: (config) => Promise<ComponentConfig> }
): Promise<RawComponentStructre> => {
  const scanStructure = async componentStructure => {
    const produceConfig = produceConfigMap[componentStructure.role] || _.identity
    return {
      ...componentStructure,
      config: await produceConfig(componentStructure.config),
      ...(componentStructure.components && {
        components: await Promise.all(
          componentStructure.components.map(component =>
            enhanceConfigByRole(component, produceConfigMap)
          )
        ),
      }),
    }
  }

  return scanStructure(structure)
}

export const enhanceStructreWithSnapshot = (structure, formSnapshot: FormSnapshot) => {
  const formStructure = _.merge({}, structure, {
    config: formSnapshot.formComponent.config,
    layout: formSnapshot.formComponent.layout,
  })
  formStructure.components = formSnapshot.components.map(component =>
    _.omit(component, 'componentRef')
  )
  return formStructure
}

export const getFormControllerType = (structure: RawComponentStructre): ControllerType => {
  const plugins = structure.config.plugins
  return _.find(plugins, { id: FormPlugin.MULTI_STEP_FORM })
    ? ControllerType.MULTI_STEP_FORM
    : ControllerType.WIX_FORMS
}

export const getComponentByRole = (
  structure: ComponentStructre,
  role: string
): ComponentStructre | undefined => {
  const primaryConnection = _.find(structure.connections.items, { isPrimary: true })
  if (primaryConnection && primaryConnection.role === role) {
    return structure
  }
  if (!structure.components) {
    return
  }
  return _.first(
    structure.components.map(component => getComponentByRole(component, role)).filter(c => c)
  )
}

export const connectComponentToConnection = (
  component: ComponentStructre,
  { role, config, controllerId }
): ComponentStructre => ({
  ...component,
  connections: {
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
    type: 'ConnectionList',
    items: [
      {
        type: 'ConnectionItem',
        role,
        config: JSON.stringify(config),
        isPrimary: true,
        controllerId,
      },
    ],
  },
})

export const limitComponentInContainer = (component: ComponentStructre, containerHeight: number) =>
  component &&
  _.merge({}, component, {
    layout: {
      y: Math.max(Math.min(component.layout.y, containerHeight - component.layout.height - 10), 0),
    },
  })
