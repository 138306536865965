import { GFPP_IDS, GFPP_ACTIONS, GFPP } from './manifest-commons'
import translations from '../../../utils/translations'
import MULTI_STEP_HELP_IDS from '../../../plugins/multi-step-form/constants/help-ids'
import { createGlobalDesignManifest, DESIGN_GROUPS } from './global-design-manifest'
import { COMPONENT_TYPES } from '../preset/fields/component-types'
import { STEP_ROLE, LAST_STEP_ROLE, ROLE_FORM } from '../../../constants/roles'

export const createMultiStepManifest = () => {
  return {
    box: {
      showFocusBox: true,
      displayName: translations.t('formBuilder'),
      behavior: { duplicatable: true },
      gfpp: {
        desktop: {
          helpId: MULTI_STEP_HELP_IDS.GFPP,
          mainAction1: {
            actionId: GFPP_IDS.NEW_FORM_SETTINGS,
            label: translations.t('formSettings.gfppTitle'),
          },
          mainAction2: {
            actionId: GFPP_IDS.MANAGE_STEPS,
            label: translations.t('multiStepForm.manageSteps.gfppTitle'),
          },
          iconButtons: {
            [GFPP_ACTIONS.SETTINGS]: GFPP.REMOVE,
            [GFPP_ACTIONS.ADD]: { actionId: GFPP_IDS.ADD_FIELD },
          },
          globalDesign: {
            ...createGlobalDesignManifest({
              containerLabel: translations.t('manifest.globalDesign.multiStepContainerCompPart'),
              customHelpId: 'c9f9aca6-1667-4c6c-949c-8e939c45db60',
              presetHelpId: 'c9f9aca6-1667-4c6c-949c-8e939c45db60',
            }),
          },
        },
        mobile: {
          /* use default configurations */
          helpId: MULTI_STEP_HELP_IDS.MOBILE_GFPP,
        },
      },
      designMapping: {
        [COMPONENT_TYPES.STATE_BOX]: {
          [DESIGN_GROUPS.FORM_BORDER_COLOR]: ['brd'],
          [DESIGN_GROUPS.FORM_BORDER_SIZE]: ['brw'],
          [DESIGN_GROUPS.FORM_SHADOW]: ['shd'],
          [DESIGN_GROUPS.FORM_CORNERS]: ['rd'],
        },
      },
    },
    steps: {
      [STEP_ROLE]: {
        designMapping: {
          [COMPONENT_TYPES.FORM_STATE]: {
            [DESIGN_GROUPS.FORM_CORNERS]: ['rd'],
          },
        },
        relatedToRole: ROLE_FORM,
        displayName: translations.t('multiStepForm.stepDisplayName'),
      },
      [LAST_STEP_ROLE]: {
        designMapping: {
          [COMPONENT_TYPES.FORM_STATE]: {
            [DESIGN_GROUPS.FORM_CORNERS]: ['rd'],
          },
        },
        relatedToRole: ROLE_FORM,
        displayName: translations.t('multiStepForm.stepDisplayName'),
      },
    },
  }
}
